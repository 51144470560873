.App {
    text-align: center;
}

.App-header {
    background-color: #282c34;
    min-height: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.chat-container {
    max-width: 800px;
    margin-right: auto;
}


.chat-messages {
    height: 70vh;
    overflow-y: auto;
    flex-direction: column;
    padding: 20px; /* Add padding to chat-messages */
}

.message {
    max-width: 80%;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    word-wrap: break-word;

}

.user-message {
    text-align: right;
    color: black;
    padding: 5px;
    border-radius: 5px;
    word-wrap: break-word;
}

.bot-message {
    align-self: flex-start;
    text-align: left;
    color: #333;
    padding: 5px;
    border-radius: 5px;
    word-wrap: break-word;
}

.bot-message-wrapper {
    display: flex;
    align-items: flex-start;
    background-color: #f1f1f1;
    width: 100%;
    justify-content: flex-start; /* Add this line */

}

.user-message-wrapper {
    display: flex;
    align-items: flex-start;
    background-color: white;
    width: 100%;
    justify-content: flex-start; /* Add this line */
}


.chat-form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    padding: 0 20px; /* Add this line */
}

.chat-form input {
    flex-grow: 1;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.chat-form button {
    background-color: #282c34;
    color: white;
    padding: 10px;
    margin-left: 10px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
}

.chat-form button:hover {
    background-color: #45a049;
}

/* Model selection styles */
.model-selection {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 10px;
    padding: 0 20px; /* Add this line */
}

.model-selection label {
    margin-right: 10px;
    font-size: 16px;
}

.model-selection select {
    font-size: 16px;
    padding: 4px 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
}

.app-container {
    display: flex;
}

.loader {
    visibility: hidden;
}

.sidebar {
    width: 20%;
    background-color: #282c34;
    border-right: 1px solid #ddd;
    padding: 20px;
    position: relative; /* Add this line */
    z-index: 1; /* Add this line */
}

.conversation-list {
    display: flex;
    flex-direction: column;
    color: white;
}

.conversation-list-item {
    display: flex;
    align-items: center;
    padding: 10px 10px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
}

.conversation-list-item:hover {
    background-color: #eee;
    color: black;
}

.conversation-id:hover {
    background-color: #eee;
    color: black;
}


.model-id {
    flex: 1;
    font-weight: bold;
}

.conversation-id {
    color: white;
}

.chat-container {
    flex: 1;
    width: 70%; /* adjust this as needed */
    display: flex;
    flex-direction: column;
    padding: 0;
}

.message-divider::before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #ccc;
}

.conversation-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 30px;
    color: white;
}

.logout-button {
    display: block;
    width: 100%;
    color: white;
    background-color: #282c34;
    border: none;
    padding: 10px 0;
    cursor: pointer;
    text-align: center;
    margin-top: 10px;
}

.logout-button:hover {
    background-color: #45a049;
}

.chat-form .generate-button {
    background-color: #282c34;
    color: white;
    padding: 10px;
    margin-left: 10px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
}

.chat-form .generate-button:hover {
    background-color: #45a049;
}

.VoiceSelectionModal div {
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
}

.VoiceSelectionModal div:hover {
    background-color: #2da224;
}

.VoiceSelectionModal .voice-list {
    max-height: 60vh;
    overflow-y: auto;
    margin-bottom: 20px;
}

.VoiceSelectionModal .voice-list div {
    cursor: pointer;
    padding: 10px;
    border: 1px solid rgba(27, 183, 16, 0.85);
    margin-bottom: 10px;
}

.VoiceSelectionModal .voice-list div:hover {
    background-color: #45a049;
    color: white;
}

.right-sidebar {
    right: 0;
    background-color: #282c34;
    border-right: 1px solid #ddd;
    position: fixed; /* Add this line */

}

.scrollable {
    overflow-y: auto;
    height: 100vh;
}

.right-sidebar h4 {
    color: white;
}

.voice-url-link {
    color: green;
}

